import * as moment from 'moment-timezone';
import * as momentBiz from 'moment-business-days';
import {vue} from '@/main';
import * as crypto from 'crypto';
import * as api from '@/shared/api';
import * as C from 'balaan_constants';
import Vue from 'vue'

/**
 * 특정 값을 뽑아내기 위해 앞, 뒤의 스트링을 정규식을 이용하여 지정하고 그 사이 값을 가져온다.
 * '<span id="aa">Name</span>'.pick('id="aa">', '<'); // 'Name'
 * '<span id="aa" class="strong">Name</span>'.pick('id="aa".*?>', '<\\/span>'); // 'Name'
 *
 * @param pre 원하는 string의 직전부분 정규식
 * @param post 원하는 string의 직후부분 정규식
 * @param idx 해당 식과 일치하는 부분이 여러개일 때 해당 index, 혹은 null 로 지정할 경우 일치하는 부분 전체
 * @param array array 형태로 반환받을 것인지 여부
 * @param oneline 개행이 있을 경우 정규식 적용이 까다로워지기에, 개행을 제거하고 진행할지의 여부
 * @param include pre, post 에 해당하는 내용을 포함할지의 여부
 * @returns {null|string|RegExpMatchArray|string[]}
 */
String.prototype.pick = function (pre, post, options = {}) {
  if (typeof options === 'number') options = {idx:options};
  let {idx=0, array=false, oneline=true, include=false, trim=true} = options;
  let reg = new RegExp(pre + "([\\s\\S]*?)" + post, "g");
  let str = oneline ? this.replace(/\r?\n/g, '') : this;
  let arr = str.match(reg);
  if (arr) {
    for (let i = 0; i < arr.length; i++) {
      if (!include) arr[i] = arr[i].replace(reg, "$1");
      if (trim) arr[i] = arr[i].trim();
    }
    if (idx == null) return arr; // all array, array=false 와 무관하다
    if (!array) return arr[(idx && idx < 0 ? arr.length + idx : idx) || 0];
    return arr.slice(idx || 0, (idx || 0) + 1);
  }
  return null;
};
String.prototype.pickAll = function (pre, post, {oneline=true, include=false, trim=true}={}) {
  return this.pick(pre, post, {idx:null, oneline, include, trim});
};

/**
 * 해당 string 중 태그 형태를 제외하고 반환한다.
 *
 * @param trim 태그 앞뒤의 공백을 제거할지 여부
 * @returns {string}
 */
String.prototype.removeTags = function (trim = false) {
  return trim ? this.replace(/\s*<!--.*?-->\s*/g, '').replace(/\s*<.*?>\s*/g, '') : this.replace(/<!--.*?-->/g, '').replace(/<.*?>/g, '');
};

/**
 * 숫자로 된 문자를 comma화 시킨다.
 * ex) 1234.12345 -> 1,234.12345
 * 문자가 들어가있다면 결과가 이상해지므로 주의
 * ex) 'a123aa345'.comma() -> 'a,123aa,345'
 *
 * @return {string}
 */
String.prototype.comma = function() {
  const [a, b] = this.split('.');
  return a.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + (b ? '.' + b : '');
};

/**
 * includes 의 reverse version.
 * ex) ['a', 'b', 'c'].includes('b') === 'b'.in(['a', 'b', 'c']) === 'b'.in('a', 'b', 'c')
 *
 * @return {boolean}
 */
String.prototype.in = function (...arr) {
  if (arr[0] == null) return false;
  if (Object.prototype.toString.call(arr[0]) === '[object Array]') {
    return arr[0].includes(this.valueOf());
  }
  return arr.includes(this.valueOf());
};

/**
 * intent 에 맞게 string 을 마스킹한다.
 * - intent:
 *   string 일 때 : phone, middle, first, last, birthday, email, addressGuide, address, ip, ipv6, empty, all
 *   number 일 때 : all, empty, reverse, randIn, randInt, random, shift:n, plus:n, minus:n
 *   그 외 타입일 때 : empty
 *
 * 아래는 개인정보보호 가이드라인에 의한 항목
 * ① 성명 중 이름의 첫 번째 글자 이상
 * ② 생년월일
 * ③ 전화번호 또는 휴대폰 전화번호의 국번
 * ④ 주소의 읍면동
 * ⑤ IP주소는 버전 4의 경우 17~24비트 영역, 버전 6의 경우 113~128비트 영역
 *
 *
 * @param {*} value           마스킹할 원본 값
 * @param {string} intent     마스킹할 방법
 * @param {number} ratio      마스킹 처리의 비중 (ratio 가 3 이면 1/3 을 마스킹)
 * @return {*}                마스킹된 결과 값
 */
String.prototype.masker = function ({intent = 'middle', ratio = null} = {}) {
  let value = this;
  // 만약 intent 가 string:number 라면 ratio 가 함께 들어온 것으로 간주한다.
  if (intent.includes(':')) {
    [intent, ratio] = intent.split(':');
    ratio = +ratio;
  }
  // array, object 인 경우 내부까지 적용한다.
  if (typeOf(value) === 'object') {
    const obj = {};
    Object.entries(value).forEach(([k, v]) => {
      obj[k] = module.exports.masker(v, {intent, ratio});
    });
    return obj;
  } else if (typeOf(value) === 'array') {
    const arr = [];
    value.forEach((e, i) => {
      arr.push(module.exports.masker(e, {intent, ratio}));
    });
    return arr;
  }

  // 숫자일 때의 처리
  if (typeof value === 'number') {
    if (intent === 'all') { // object 등에서 하위 요소를 전부 마스킹하려 할 때 사용되므로 0 으로 바꾼다.
      return 0;
    } else if (intent === 'reverse') { // 순서를 뒤집은 수
      return +value.toString().split('').reverse().join('');
    } else if (intent === 'randIn') { // 해당 숫자의 범위 내에서 랜덤한 정수
      return Math.ceil(Math.random() * value);
    } else if (intent === 'randInt') { // ratio 범위 내에서 랜덤한 정수, ratio 가 없다면 11자리 내에서 랜덤한 정수(maria db 고려)
      return Math.ceil(Math.random() * (ratio || 99999999999));
    } else if (intent === 'random') { // ratio 범위 내에서 랜덤한 수, ratio 가 없다면 0과 1 사이의 랜덤한 실수
      return Math.random() * (ratio || 1);
    } else if (intent === 'shift') { // ratio 만큼 shifting 한 수, ratio 가 없다면 4
      return +(value.toString().slice(ratio || 4) + value.toString().slice(0, ratio || 4));
    } else if (intent === 'plus') { // ratio 만큼 더한 수
      return value + (ratio || 0);
    } else if (intent === 'minus') { // ratio 만큼 뺀 수
      return value - (ratio || 0);
    }
  }

  // 숫자, 문자도 아닐 때의 처리
  if (typeof value !== 'string') {
    if (intent === 'empty') {
      return null;
    }
    return value;
  }

  if (ratio === null) ratio = 3; // default 값 설정

  // 010-1234-2345 -> 010-****-2345
  if (intent === 'phone') {
    // 전화번호는 200-0000 의 7 자리부터 0504-5678-1234 의 12 자리까지 가능 (국제번호 제외)
    // 특수기호를 제외하고 숫자만 남겨놓고 시작
    // 가운데의 1/3 영역을 마스킹한다. 7~9 = 3자리, 10~12 = 4자리
    // substring 시작, 끝을 잡는다.
    const tokens = value.split(/\D/g);
    const splitter = value.match(/\D/g);
    if (tokens.length === 3) { // - 등의 기호로 나뉜 국번을 특정할 수 있다면 국번만 교체
      return tokens[0] + splitter[0] + '*'.padEnd(tokens[1].length, '*') + splitter[1] + tokens[2];
    }
    const num = tokens.join('');
    const stIdx = Math.floor(num.length / 3);
    const maskLen = Math.ceil(num.length / 3);
    const maskedNum = num.slice(0, stIdx) + '*'.padEnd(maskLen, '*') + num.slice(stIdx + maskLen, num.length);
    let masked = '';
    let maskedIdx = 0;
    tokens.forEach((t, i) => {
      masked += maskedNum.slice(maskedIdx, maskedIdx + t.length) + (splitter && splitter[i] || '');
      maskedIdx += t.length;
    });
    return masked;
  } else if (intent === 'middle') {
    // 김혜수 -> 김*수
    // 김일 -> 김*
    // 김베드로 -> 김**로
    const stIdx = Math.floor(value.length / ratio) || 1; // 성이 아닌 이름의 첫 글자로 지정되기 위해 0 이면 1 로 교체
    const maskLen = Math.ceil(value.length / ratio);
    return value.slice(0, stIdx) + '*'.padEnd(maskLen, '*') + value.slice(stIdx + maskLen, value.length);
  } else if (intent === 'first') {
    const maskLen = Math.ceil(value.length / ratio);
    return '*'.padEnd(maskLen, '*') + value.slice(maskLen, value.length);
  } else if (intent === 'last') {
    const maskLen = Math.ceil(value.length / ratio);
    return value.slice(0, value.length - maskLen) + '*'.padEnd(maskLen, '*');
  } else if (intent === 'birthday') {
    // 숫자를 전부 마스킹
    return value.replace(/\d/g, '*');
  } else if (intent === 'email') {
    // http://javakorean.com/%EC%9E%90%EB%B0%94%EC%8A%A4%ED%81%AC%EB%A6%BD%ED%8A%B8-%EB%A7%88%EC%8A%A4%ED%82%B9-%EC%B2%98%EB%A6%AC-%EC%A0%95%EA%B7%9C%EC%8B%9D/
    const maskLen = Math.ceil(value.split('@')[0].length / ratio); // email 중 id 영역
    // return text.replace(new RegExp('.{0,' + maskLen + '}(?=@)', 'g'), '*'); // 마스킹된 * 의 갯수가 2개로 고정
    return value.replace(new RegExp('.(?=.{0,' + (maskLen - 1) + '}@)', 'g'), '*'); // 마스킹된 * 의 갯수가 교체된 문자수와 동일
  } else if (intent === 'addressGuide') {
    // 가이드에 따른 읍면동 에 리 를 추가하고 도로명주소를 고려한 길로 까지 추가한 버전
    const targets = value.match(/[^\s]+(?=[읍면동리길로][^ㄱ-ㅎ가-힣ㅏ-ㅣ])/g);
    if (!targets) {
      return value;
    }
    targets.forEach(t => value = value.replace(t, '*'.padEnd(t.length, '*')));
    return value;
  } else if (intent === 'address') {
    // 도로명주소에 따라 ~로, ~길 인 주소도 있고, 지번주소에 대한 상술이 포함되는 케이스도 있으므로 읍면동 으로는 불충분하다.
    // ex) 서울특별시 강서구 양천로75길 19 (염창동, 강변힐스테이트아파트) 000동 0000호
    // 읍면동리길로 마스킹 + 후반부 절반 마스킹 으로 한다.
    const targets = value.match(/[^\s]+(?=[읍면동리길로][^ㄱ-ㅎ가-힣ㅏ-ㅣ])/g);
    if (!targets) {
      return value;
    }
    targets.forEach(t => value = value.replace(t, '*'.padEnd(t.length, '*')));
    const maskLen = Math.ceil(value.length / 2);
    return value.slice(0, value.length - maskLen) + '*'.padEnd(maskLen, '*');
  } else if (intent === 'ip') {
    const tokens = value.split('.');
    tokens[2] = '***'; // 자릿수 추정 불가토록 3자리로 마스킹
    return tokens.join('.');
  } else if (intent === 'ipv6') {
    // http://daplus.net/regex-%EC%9C%A0%ED%9A%A8%ED%95%9C-ipv6-%EC%A3%BC%EC%86%8C%EC%99%80-%EC%9D%BC%EC%B9%98%ED%95%98%EB%8A%94-%EC%A0%95%EA%B7%9C%EC%8B%9D/
    const tokens = value.split(':');
    if (tokens.length === 8) {
      tokens[6] = '****'; // 자릿수 추정 불가토록 4자리로 마스킹
    } else {
      tokens[tokens.length - 1] = '****'; // 마지막 자리를 마스킹
    }
    return tokens.join(':');
  } else if (intent === 'empty') { // 빈 스트링으로 반환
    return '';
  } else if (intent === 'all') { // 전체 마스킹, 길이는 4자 미만일땐 4자로 설정한 뒤 1/2 길이 사이로 랜덤하게 지정
    if (value.length < 4) value = '****';
    else value = value.replace(/./g, '*');
    return value.slice(0, value.length - Math.floor(Math.random() * value.length / 2));
  } else {
    // intent 가 없거나 일치하지 않는다면 전체 마스킹
    return value.replace(/./g, '*');
  }
};

/**
 * 숫자를 comma화 하여 문자로 만든다.
 * ex) 1234.12345 -> 1,234.12345
 *
 * @returns {string}
 */
Number.prototype.comma = function () {
  const [a, b] = this.toString().split('.');
  return a.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + (b ? '.' + b : '');
};

/**
 * includes 의 reverse version.
 * ex) [1, 2, 3].includes(2) === (2).in([1, 2, 3]) === (2).in(1, 2, 3)
 *
 * @return {boolean}
 */
Number.prototype.in = function (...arr) {
  if (arr[0] == null) return false;
  if (Object.prototype.toString.call(arr[0]) === '[object Array]') {
    return arr[0].includes(this.valueOf());
  }
  return arr.includes(this.valueOf());
};

/**
 * array 의 element 혹은 object 의 key 에 해당하는 값을 더해서 반환한다.
 * falsy 한 값들은 0 으로 처리한다.
 * ex) [1, 2].sum() -> 3
 * ex) [{a: 1}, {a: 2}, {b: 3}].sum('a') -> 3
 * ex) [{goods: {price: 10000}}, {goods: {price: 20000}}].sum('goods.price') -> 30000
 * ex) [{options: [{stock: 2}, {stock: 3}]}, {options: [{stock: 1}]}].sum('options.stock') -> 6
 *
 * @returns {number}
 */
Array.prototype.sum = function(key) {
  if (key) {
    const keys = key.split('.');
    const getFieldValue = (obj, k) => Object.prototype.toString.call(obj) === '[object Object]' ? obj[k] :
      Object.prototype.toString.call(obj) === '[object Array]' ? obj.map(e => getFieldValue(e, k)) :
        obj ? obj[k] : null;
    const arr = this.map(e => {
      return keys.reduce((obj, k) => {
        return getFieldValue(obj, k);
      }, e);
    });
    return arr.flat().reduce((a, b) => (a || 0) + (b || 0), 0);
  }

  return this.reduce((a, b) => (a || 0) + (b || 0), 0);
};

/**
 * array 의 element 혹은 object 의 key 에 해당하는 값을 중복 제거해서 반환한다.
 * ex) [1, 1, 2].set() => [1, 2]
 * ex) [{no: 1, a: 1}, {no: 2, a: 1}, {no: 2, b: 3}].set('no') => [1, 2]
 * ex) [{a: {b: 1}}, {a: {b: 2}}].set('a.b') => [1, 2]
 * ex) [{options: [{Size: 'S'}, {Size: 'M'}]}, {options: [{Size: 'S'}, {Size: 'L'}]}].set('options.Size') => ['S', 'M', 'L']
 */
Array.prototype.set = function(key) {
  if (key) {
    const keys = key.split('.');
    const getFieldValue = (obj, k) => Object.prototype.toString.call(obj) === '[object Object]' ? obj[k] :
      Object.prototype.toString.call(obj) === '[object Array]' ? obj.map(e => getFieldValue(e, k)) :
        obj ? obj[k] : null;
    const arr = this.map(e => {
      return keys.reduce((obj, k) => {
        return getFieldValue(obj, k);
      }, e);
    });
    return Array.from(new Set(arr.flat()));
  }
  return Array.from(new Set(this));
};

/**
 * [[key, value], ...] 형태의 array 에 대해서 {key: value} 형태의 object 를 만들어 반환한다.
 * [1, 'a'] 등의 형태라면 {'1': true, 'a': true} 형태로 반환한다.
 * @returns {object}
 */
Array.prototype.dict = function() {
  const obj = {};
  this.forEach(e => {
    if (Object.prototype.toString.call(e) === '[object Array]') {
      obj[e[0]] = e[1];
    } else {
      obj[e + ''] = true;
    }
  });
  return obj;
}

/**
 * array 를 특정 key 를 기준으로 정렬한다.
 * vA, vB 의 값이 문자라면 결과는 localeCompare 에 의존한다.
 * vA, vB 의 값이 숫자라면 - 연산을 통해 정렬한다.
 * key 는 a.b 처럼 중첩구조를 사용할 수 있다.
 * vA 의 값이 숫자나 문자가 아닐 경우 혹은 서로 상이할 때는 기본 sort 의 룰에 맞게 string 으로 변환한 뒤 비교한다.
 * null 계열 값은 순서무관 맨 뒤로 보내도록 한다.
 * ex) [{a: 1}, {a: 2, b: 2}, {a: 2}, {a: 2, b: 'p'}, {a: 2, b: 1}].keySort('a,b:-1') -> [{a: 1}, {a: 2, b: 'p'}, {a: 2, b: 2}, {a: 2, b: -1}, {a: 2}]
 *
 * @returns {array}
 */
Array.prototype.keySort = function(keys) {
  const keyPair = keys.split(',').map(e => {
    const [key, orderby] = e.split(':');
    return [key, +(orderby || 1)];
  });
  this.sort((a, b) => {
    for (const [key, orderby] of keyPair) {
      const vA = key.split('.').reduce((a, k) => a ? a[k] : null, a); const vB = key.split('.').reduce((b, k) => b ? b[k] : null, b);
      let comp = 0;
      if (vA == null && vB == null) continue;
      if (vA == null && vB != null) return 1;
      if (vB == null && vA != null) return -1;
      if (typeof vA === 'number' && typeof vB === 'number') {
        comp = (vA - vB) * orderby;
      } else {
        comp = (vA + '').localeCompare((vB + '')) * orderby;
      }
      if (comp !== 0) return comp;
    }
    return 0;
  });
  return this;
};

/**
 * array 에 object 가 들어있다는 가정 하에 특정 key 들을 projection 하여 반환한다.
 * projection 은 mysql select style string (= 'no, user.name name') 이거나 mongodb style projection object (= {no: 1, name: 1}) 이다.
 * 이 과정에서 array 에 들어있는 것이 object 가 아니라면 에러가 발생한다.
 * removeEmpty = true 라면 projection 한 뒤 key 가 남아있지 않은 object 는 제거된다. ex) [{a:1}].select('b', true) => []
 * alias 없이 select style 을 쓴 다면, a.b 처럼 1 depth 까지만 되고 a.b.c 는 되지 않는다(c 는 무시된다)
 *
 * [{no:1, a:1}, {a:2, b:3, c:4}, {a:3}].select('a,b') => [{a:1}, {a:2, b:3}, {a:3}]
 */
Array.prototype.select = function(projection, removeEmpty = false) {
  if (!projection) return this;

  if (typeof projection === 'string') {
    const remap = {};
    const project = {};
    projection.trim().split(/\s*,\s*/).forEach(e => {
      const [key, _, alias] = e.split(/\s+(as\s+)?/i);
      project[key] = 1;
      if (alias) remap[key] = alias;
    });
    if (Object.keys(remap).length > 0) { // remap 할 필요가 있으면 별도로 진행한다.
      const keys = Object.keys(project);
      return this.map(e => {
        const obj = {};
        for (const key of keys) {
          if (remap[key]) {
            obj[remap[key]] = key.split('.').reduce((o, k) => o == null ? null : o[k], e);
          } else {
            obj[key] = key.includes('.') ? key.split('.').reduce((o, k) => o == null ? null : o[k], e) : e[key];
          }
        }
        return obj;
      });
    }
    projection = project;
  }

  const projectionHasTrue = Object.values(projection).some(v => v); // 1, true 등이 하나라도 있다면 projection 에 표시되지 않은 다른 값들은 0 이다
  if (projectionHasTrue) {
    const trueKeyMap = {}; // projection = {a: 1, 'b.c': 1} => trueKeyMap = {a: 1, b: 'c'}
    Object.entries(projection).filter(e => e[1]).map(e => {
      const [k, ...v] = e[0].split('.');
      trueKeyMap[k] = v.length === 0 ? 1 : v;
    });
    const newArr = [];
    this.forEach(e => {
      const newObj = {};
      Object.entries(e).filter(e => trueKeyMap[e[0]]).forEach(([k, v]) => {
        const subKeys = trueKeyMap[k];
        if (typeof subKeys === 'number') newObj[k] = v;
        else {
          if (Object.prototype.toString.call(v) === '[object Object]') {
            newObj[k] = {[subKeys[0]]: v[subKeys[0]]};
          } else if (Object.prototype.toString.call(v) === '[object Array]') {
            newObj[k] = v.map(e => ({[subKeys[0]]: e[subKeys[0]]}));
          } else {
            newObj[k] = v;
          }
        }
      });
      if (!removeEmpty || Object.keys(newObj).length) newArr.push(newObj);
    });
    return newArr;
  } else {
    const falseKeyMap = {};
    Object.keys(projection).forEach(k => falseKeyMap[k] = true);
    const newArr = [];
    this.forEach(e => {
      const newObj = {};
      Object.entries(e).filter(e => !falseKeyMap[e[0]]).forEach(([k, v]) => newObj[k] = v);
      if (!removeEmpty || Object.keys(newObj).length) newArr.push(newObj);
    });
    return newArr;
  }
};

/**
 * array 에 대해서 set 의 개념으로 equal 한지 검사한다.
 * 파라미터는 array 이거나 set 이다.
 *
 * @param set
 * @returns {boolean}
 */
Array.prototype.eq = function (set) {
  if (Object.prototype.toString.call(set) === '[object Array]') set = new Set(set);
  const thisSet = new Set(this);
  if (thisSet.size !== set.size) return false;
  for (const a of thisSet) if (!set.has(a)) return false;
  return true;
}


/**
 * set 에 대해서 set 의 개념으로 equal 한지 검사한다.
 * 파라미터는 array 이거나 set 이다.
 *
 * @param set
 * @returns {boolean}
 */
Set.prototype.eq = function (set) {
  if (Object.prototype.toString.call(set) === '[object Array]') set = new Set(set);
  if (this.size !== set.size) return false;
  for (const a of this) if (!set.has(a)) return false;
  return true;
}


/**
 * pfs = profile function string
 * eval 로 함수를 만들어낼 때 동일 scope 여야 하기 때문에 굳이 string 을 반환하고 해당 scope 에서 직접 eval 하여 함수를 만들도록 한다.
 *
 * ex) app.get('/pfs', async (req, res) => { res.json({ok: 1}); });
 *  => app.get('/pfs', eval((async (req, res) => { res.json({ok: 1}); }).pfs()));
 *
 * pfs 는 선언된 곳에서 사용해야 하며 require 해온 곳에서 사용하면 scope 가 맞지 않아 에러가 난다. scope 문제가 없다면 가능하다.
 * (x) require('some').api.pfs()
 * (o) (async function aa() {}).pfs()
 *
 * @param {boolean} log           true 라면 console 에 log 를 찍는다.
 * @param {string} logColl        second.log.pfs 나 blu.mongo.db.log.pfs 처럼 직접 사용 가능한 콜력션을 넣으면 그곳에 로그를 쌓는다.
 * @returns {string}
 */
Function.prototype.pfs = function ({name = '', log = true, logColl = ''} = {}) {
  const fnName = name || this.name;
  const stack = new Error().stack.split('\n');
  let fnSrc = '';
  stack.forEach((e, i) => {
    if (e.match(/Function\.pfs/)) fnSrc = stack[i + 1];
  });
  fnSrc = fnSrc.match(/\((.+)\)/) ? fnSrc.match(/\((.+)\)/)[1] : fnSrc.replace('    at ', '');
  const fnFile = !fnSrc ? '' : fnSrc.replace(/\\/g, '/').replace(/^[A-Z]:\//, '/')
    .replace(new RegExp(`.*?/${process.env.APP_DIR}`), '').split(' ')[0].split(':').slice(0, 2).join(':');
  // console.log(fnSrc, fnFile);

  let fnStr = this.toString();
  try {
    eval(fnStr);
  } catch (e) {
    // async a() {}, a() {} 같은 생략 패턴
    if (fnStr.startsWith('async')) {
      fnStr = fnStr.replace(/^async (function )?/, 'async function ');
    } else {
      fnStr = 'function ' + fnStr; // normal function
    }
  }
  fnStr = 'const __fn = ' + fnStr + ';';
  // line 별로 끊고, 하나씩 추가하면서 validate 한다.
  const jsArr = fnStr.split(/\r?\n/);
  jsArr[0] += `;let __st = +new Date;`;
  for (let i = 1; i < jsArr.length - 1; i++) {
    const oldLine = jsArr[i];
    if (oldLine.match(/^\s*$/)) continue;
    jsArr[i] = `;\`;\`+';'+";"; lap[${i}] = (lap[${i}] || {code: '${oldLine.replace(/'/g, "\\'")}', cnt: 0, elapsed: 0}); lap[${i}].cnt++; lap[${i}].elapsed += +new Date - __st; __st = +new Date;` + jsArr[i];
    const fnStr = jsArr.join('\n');
    try {
      eval(fnStr);
    } catch(e) {
      jsArr[i] = oldLine;
    }
  }
  return `async (...args) => { const lap = {}; ` + jsArr.join('\n') + `
    const st = +new Date;
    const __result = await __fn(...args);
    const elapsed = (new Date - st) / 1000;

    // profile 결과는 한 칸씩 밀어야 한다.
    const lineNoMap = {};
    const lines = Object.keys(lap).map(e => +e).sort((a, b) => a - b);
    lines.forEach((e, i) => lineNoMap[e] = lines[i + 1]);
    const profiles = Object.entries(lap)
      .map(([line, {code}]) => {
        // script 가 return 한 줄이라면 측정이 불가하여 전체 시간으로 대체
        const obj = lap[lineNoMap[line]] || {cnt: 1, elapsed: lines.length === 1 ? obj.elapsed * 1000 : 0};
        return {line: +line, code, cnt: obj.cnt, elapsed: obj.elapsed};
      });
    const profileLines = profiles.map(obj => 'line ' + obj.line + ':' + obj.code.slice(0, 30).padEnd(30, ' ')
      + ' (run: ' + obj.cnt + ', elapsed: ' + (obj.elapsed / 1000) + ' s)');

    ${log ? `console.log('${fnName || 'anonymous'}() elapsed: ' + elapsed + ' s\\n' + profileLines.join('\\n'));` : ''}
    ${logColl ? `blu.mongo.${logColl}.insertOne({name: '', file: '${fnFile}', profiles, ...blu.getTimestamp()});` : ''}
    return __result;
  }
  `;
};


/**
 * HTML Escape helper utility
 * https://developers.google.com/web/updates/2015/01/ES6-Template-Strings
 */
(function () {
  let reEscape = /[&<>'"]/g,
    reUnescape = /&(?:amp|#38|lt|#60|gt|#62|apos|#39|quot|#34);/g,
    oEscape = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      "'": '&#39;',
      '"': '&quot;'
    },
    oUnescape = {
      '&amp;': '&',
      '&#38;': '&',
      '&lt;': '<',
      '&#60;': '<',
      '&gt;': '>',
      '&#62;': '>',
      '&apos;': "'",
      '&#39;': "'",
      '&quot;': '"',
      '&#34;': '"'
    };
  String.prototype.escapeHtml = function () {
    return this.replace(reEscape, e=>oEscape[e]);
  };
  String.prototype.unescapeHtml = function () {
    return this.replace(reUnescape, e=>oUnescape[e]);
  };
}());

export function comma(n) {
  if (n == null) {
    return '';
  }
  // return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  let [a,b] = (n+'').split('.');
  return a.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (b ? '.' + b : '');
}

export function typeOf(obj) {
  return Object.prototype.toString.call(obj).replace('[object ','').replace(']','').toLowerCase();
}

export function dt(t) {
  return moment(t).format('YYYYMMDD_HHmm');
}

export function kst(obj, format) {
  return moment(obj).tz("Asia/Seoul").format(format || 'YYYY-MM-DD');
}
export function kstDT(obj) {
  return moment(obj).tz("Asia/Seoul").format('YYYY-MM-DD HH:mm:ss');
}
export function kstDHM(obj) {
  return moment(obj).tz("Asia/Seoul").format('YYYY-MM-DD HH:mm');
}
export function kstD(obj) {
  return moment(obj).tz("Asia/Seoul").format('YYYY-MM-DD');
}
export function weekday(obj) {
  return {0:'일',1:'월',2:'화',3:'수',4:'목',5:'금',6:'토',}[moment(obj).tz("Asia/Seoul").weekday()];
}
/**
 * 그냥 momentBiz() 로 businessDiff 를 하면 하루차이의 결과가 2 로 나온다. .startOf('day') 를 해줘야 한다.
 * 어딘가에서 아래처럼 휴일설정을 해줘야 한다. import * as momentBiz from 'moment-business-days'; 만으로도 설정은 공유된다.
 momentBiz.updateLocale('kr', {
      holidays: holidays,
      holidayFormat: 'YYYY-MM-DD'
    });
 */
export function bizDiff(from, to) {
  if (!to) to = momentBiz().startOf('day');
  return momentBiz(from).businessDiff(momentBiz(to), 'days');
}

export async function sleep(sec) {
  return new Promise((resolve, reject) => {
    setTimeout(resolve, sec * 1000);
  });
}

export function clone(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function round(n, i) {
  if (i == null) return Math.round(n);
  return Math.round(n * Math.pow(10, i)) / Math.pow(10, i);
}

export function ceil(n, i) {
  if (i == null) return Math.ceil(n);
  return Math.ceil(n * Math.pow(10, i)) / Math.pow(10, i);
}

export function floor(n, i) {
  if (i == null) return Math.floor(n);
  return Math.floor(n * Math.pow(10, i)) / Math.pow(10, i);
}

export function rnc (n, i) { // round and comma
  return comma(round(n, i));
}
export function cnc (n, i) { // ceil and comma
  return comma(ceil(n, i));
}

export function fnc (n, i) { // floor and comma
  return comma(floor(n, i));
}

export function delta (n, i) {
  return (n > 0 ? '+' : '') + comma(round(n, i));
}

export function nvl(v, other) {
  return v == null ? other : v;
}
export function ifNull(v, other) {
  return v == null ? other : v;
}

export function ifEmpty(v, other) {
  return v === '' ? other : v;
}

export function open(...args) {
  return window.open(...args);
}

export function sha256(plain) {
  return crypto.createHash('sha256').update(plain).digest('base64');
}

export function alert(content, options = {}) {
  if (typeof(options) === 'string') options = {title:options};
  Object.assign(options, {
    headerClass: 'p-2',
    footerClass: 'p-2',
    centered: true,
    noCloseOnBackdrop: true,
    okTitle: '확인',
    // noCloseOnEsc: true,
  }, options);

  if (typeof(content) === 'undefined') {
    return;
  }

  return vue.$bvModal.msgBoxOk(vue.$createElement('div', {domProps: {innerHTML: content}}), options);
}

export function log(...args) {
  console.log(...args);
}

export function glog(value, key, desc, ...params) {
  return;
  let stack = new Error().stack.split('\n')[2];
  api.postJson('/dev/glog', {value, key, desc, stack, params});
}

export function normalizeKey(k, toSymbol) {
  if (~k.indexOf('.') || ~k.indexOf('$')) {
    k = k.replace(/\./g, toSymbol || ',');
    k = k.replace(/\$/g, toSymbol || '_');
  }
  return k;
}

export function badge(value, {variant, title} = {}) {
  const arr = {
    ...C.BADGE_MAP,
    unregistered: ['등록전', 'secondary'],
  }[value];
  variant = variant || (arr && arr[1] || 'secondary');
  title = title || (arr && arr[2] ? arr[2] : '');
  if (!variant.match(/-/)) variant = `badge-${variant}`;
  return `<span class="badge ${variant} mr-1" title="${title.replace(/"/g, '＂')}">${arr ? arr[0] : value || ''}</span>`;
}

/**
 * YYYY-MM-DD HH:mm:ss 의 시각 형태를 축약하여 반환한다.
 * - 현재와 동일한 년도라면 년도를 생략한다.
 * - 현재와 다른 년도라면 시간 이하를 생략한다.
 *
 * @param {string} dt
 * @returns {string}
 */
export function reduceDT(dt) {
  if (typeOf(dt) !== 'string') return dt;
  if (dt.slice(0, 4) !== new Date().getFullYear().toString()) {
    return dt.slice(0, 10); // YYYY-MM-DD
  }
  return dt.slice(5, 16); // MM-DD HH:mm
}

/**
 * obj 를 key 로 localStorage에 저장한다.
 * filter 가 있을 경우 해당 필드만 저장한다.
 */
export function setStatus(key, obj, filter) {
  let j = {};
  if (filter) {
    j = getStatus(key) || {};
    filter.split(',').forEach(k=>j[k] = obj[k]);
  } else {
    j = {...obj};
  }
  localStorage.setItem(key + '.Status', JSON.stringify(j));
}

/**
 * key 로 localStorage에서 json 을 가져와서 target 에 set 한다.
 * target 이 없을 경우 값을 반환한다.
 * filter 가 있을 경우 해당 필드만 set 한다.
 */
export function getStatus(key, target, filter) {
  let lastStatus = JSON.parse(localStorage.getItem(key + '.Status'));
  if (target != null && lastStatus) (filter ? filter.split(',') : Object.keys(lastStatus)).forEach(k=>{if (lastStatus[k] != null) target[k] = lastStatus[k]});
  return lastStatus;
}

/**
 * arr 를 특정 key 를 기준으로 map 화 한다.
 * key는 a.b 로 object access 가 가능하며 a.b,a.c 로 pair를 이루는 것도 가능하다.
 * fields 는 'a,b,c', ['a','b'], {a:1, b:'a', c:true}, false 등의 형태를 지원한다.
 * fields 가 false 라면 doc의 전체 데이터를 넣는다.
 *
 * arr2map([{a:{b:1,c:2}}], 'a.b,a.c')
 => {1|2: {a: {…}}
 */
export function arr2map(arr, key, fields = false, {keySeperator='|'} = {}) {
  const map = {}; let projection;
  if (!arr) return map; // null check
  if (key == null) { // 단순 array
    arr.forEach(e => map[e] = true);
    return map;
  }
  if (typeof (fields) === 'string') {
    projection = fields.split(',');
  } else if (typeOf(fields) === 'object') {
    projection = Object.keys(fields);
  } else if (typeOf(fields) === 'array') {
    projection = fields;
  }

  key = key.split(',');
  arr.forEach(e=>{
    let k = key.map(k=>{
      return k.split('.').reduce((e,a)=>e[a], e);
    }).join(keySeperator);
    if (fields === true) { // key의 존재여부만 확인하기 위해 true 를 넣는다.
      map[k] = true;
    } else if (fields === false) { // 모든 값
      map[k] = e;
    } else if (fields && projection.length === 1) {
      map[k] = e[projection[0]];
    } else {
      const obj = {};
      projection.forEach(k => obj[k] = e[k]);
      map[k] = obj;
    }
  });
  return map;
}

/**
 * arr 를 특정 key 를 기준으로 map 화 한다.
 * key는 a.b 로 object access 가 가능하며 a.b,a.c 로 pair를 이루는 것도 가능하다.
 * fields 는 'a,b,c', ['a','b'], {a:1, b:'a', c:true}, false 등의 형태를 지원한다.
 * fields 가 false 라면 doc의 전체 데이터를 넣는다.
 * map의 value는 [arr[0], ..] 이다.
 */
export function arr2multi(arr, key, fields = false, {keySeperator='|'} = {}) {
  let map = {}, projection;
  if (key == null) { // 단순 array
    arr.forEach(e => {
      map[e] = (map[e] || 0);
      map[e] += 1;
    });
    return map;
  }
  if (typeof(fields) === 'string') {
    projection = fields.split(',');
  } else if (typeOf(fields) === 'object') {
    projection = Object.keys(fields);
  } else if (typeOf(fields) === 'array') {
    projection = fields;
  }
  key = key.split(',');
  arr.forEach(e => {
    const k = key.map(k => {
      return k.split('.').reduce((e, a) => e[a], e);
    }).join(keySeperator);
    const v = map[k] = map[k] || [];
    let obj;
    if (fields === true) { // key의 존재여부만 확인하기 위해 true 를 넣는다.
      obj = true;
    } else if (fields === false) { // 모든 값
      obj = e;
    } else if (fields && projection.length === 1) {
      obj = e[projection[0]];
    } else {
      obj = {};
      projection.forEach(k => obj[k] = e[k]);
    }
    v.push(obj);
  });
  return map;
}

/**
 * array 를 받아서 중복을 제거하여 반환한다.
 * set([1,1,2]) => [1,2]
 * set([{no:1, a:1}, {no:2, a:1}, {no:2, b:3}], 'no') => [1,2]
 */
export function set(arr, key) {
  if (key) arr = arr.map(e => e[key]);
  return Array.from(new Set(arr));
}


export function calcDate(st, ed, rel) {
  // rel은 st나 ed가 오늘이 아닌 서로를 기준으로 결정됨을 의미한다. ex) sd = yesterday, ed = 1 week 라면 ed 는 6일뒤
  let days = {};
  const reserved = {
    today: moment().format('YYYY-MM-DD'),
    yesterday: moment().add(-1, 'day').format('YYYY-MM-DD'),
  };
  for (let [k,v] of Object.entries({dateFrom: st, dateTo: ed})) {
    if (rel === k) continue; // 상대적으로 결정된다. [1, 'day'] 등의 array 형식만 가능
    if (typeof(v) === 'string') { // 'today'
      v = reserved[v] || v;
    } else if (typeOf(v) === 'array') {
      if (typeof(v[0]) === 'number') { // v = [1, 'day']
        v = moment().add(...v).format('YYYY-MM-DD');
      } else { // v = ['add', [1, 'day']], ['startOf', ['day']]
        v = moment()[v[0]](...v[1]).format('YYYY-MM-DD');
      }
    } else if (typeof(v) === 'number') {
      v = moment().add(v, 'days').format('YYYY-MM-DD');
    } else {
      v = reserved.today;
    }
    days[k] = v;
  }
  if (rel) {
    let v, base;
    if (rel === 'dateFrom') {
      v = st; base = days.dateTo;
      if (typeOf(st) !== 'array') {
        return {...days, dateFrom:reserved.today};
      }
    } else if (rel === 'dateTo') {
      v = ed; base = days.dateFrom;
      if (typeOf(ed) !== 'array') {
        return {...days, dateTo:reserved.today};
      }
    } else {
      return;
    }
    if (typeof(v[0]) === 'number') { // v = [1, 'day']
      v = moment(base).add(...v).format('YYYY-MM-DD');
    } else { // v = ['add', [1, 'day']], ['startOf', ['day']]
      v = moment(base)[v[0]](...v[1]).format('YYYY-MM-DD');
    }
    days[rel] = v;
  }
  return days;
}

/**
 * 주어진 텍스트를 클립보드로 복사한다.
 * 모달이 떠 있다면 focusin 이벤트를 받아서 focusHandler 에서 focus 를 취소해버려서 복사가 안된다.
 * 이 때는 모달 안에 투명 textarea를 위치하도록 한다.
 * @param {string} text    복사할 내용
 * @returns {boolean}      복사가 되었는지 여부
 */
export function copyToClipboard(text) {
  /*
    231103: 크롬에서 아래처럼 최신버전임에도 카피가 동작하지 않아서 navigator 방식을 주석처리하고 다시 돌아감

    Chrome이 최신 버전입니다.
    버전 119.0.6045.106(공식 빌드) (64비트)
   */
  // navigator.clipboard.writeText(text).then();
  // return true;

  // 1) Add the text to the DOM (usually achieved with a hidden input field)
  let textarea = document.createElement('textarea');
  // input.type = 'hidden'; // type hidden 이나 visibility hidden 이면 복사가 안된다.
  textarea.style = 'position:fixed;top:0;left:100px;opacity:0;height:10px;z-index:99999'; // width가 1px 면 복사가 안된다
  textarea.value = text;
  if (document.getElementsByClassName('modal-content').length) {
    document.getElementsByClassName('modal-content')[document.getElementsByClassName('modal-content').length - 1].appendChild(textarea);
  } else {
    document.getElementsByClassName('app')[0].appendChild(textarea);
  }

  // 2) Select the text
  textarea.focus();
  textarea.select();

  // 3) Copy text to clipboard
  const isSuccessful = document.execCommand('copy');

  // 4) Catch errors
  if (!isSuccessful) {
    console.error('Failed to copy text.');
  }
  textarea.remove();

  return isSuccessful;
}
export function copyAlert(text, {msg = '복사되었습니다'} = {}) {
  const result = copyToClipboard(text);
  if (result) Vue.prototype.$alertTop(msg);
  else Vue.prototype.$alertTop('에러가 발생했습니다');
}

export function getImageSrc(item, idx, key) {
  if (!key || !item.images || !item.images.length) {
    if (!item.img_urls || !item.img_urls.length) return 'https://i.balaan.io/blank/noimg_goods_200.webp';
    return item.img_urls[idx % item.img_urls.length];
  }
  return 'https://i.balaan.io/' + item.image_path + item.images[idx % item.images.length][key];
}

export function isNumeric(num) {
  num = String(num)
  // 모든 10진수 (-부호 가능, 자릿수구분기호 불가능, 소수점 가능)
  const regex = /^(-?)[0-9]+(\.[0-9]+)?$/g;
  return regex.test(num);
}

export function expandRole(roles) {
  let arr = [];
  roles.forEach(e=>{
    if (C.ROLE_REL[e]) arr = arr.concat(expandRole(C.ROLE_REL[e].split(',')));
    arr.push(e);
  });
  return Array.from(new Set(arr));
}
