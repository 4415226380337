import router from '@/router';
import store from '@/store';
import * as utils from '@/shared/utils';
import * as momentBiz from 'moment-business-days';
import Vue from 'vue';

const {state: S} = store;

export const SellerId = window.webpackHotUpdate ? 'ncp_1njh2a_02' : 'balaan0601';
export const MallID = SellerId;
export const SS_API_PATH = '/ss/direct/';

export const HOST = 'http://' + location.hostname;
// export const FEED_HOST = window.webpackHotUpdate ? 'http://localhost:8081' : 'https://feed0.balaan.io';
// export const FEED_HOST = 'http://localhost:8081';
export const FEED_HOST = 'https://feed0.balaan.io';
function getCommonHeaders() {
  let clientRoute;
  try { // 에러가 나는지는 확인되지 않았으나 일반적인 방법은 아니기에 try 해 둔다.
    clientRoute = router.history.current.matched.slice(-1)[0].path;
  } catch(e) {
    //
  }
  return {
    'Accept-Encoding': 'gzip, deflate',
    'Client-Ver': Vue.prototype.$clientVer,
    'Client-Location': location.href.replace(location.origin, ''),
    ...(clientRoute && {'Client-Route': clientRoute}),
    'Client-Host': location.host
  };
}

export function getHost() {
  const pfs = localStorage.getItem('__pfs') ? '/__pfs' : '';
  return (window.webpackHotUpdate ? HOST + ':' + +(localStorage.getItem('port') || 3030) : '') + pfs;
}

export async function getFeed(uri) {
  return await (await fetch(FEED_HOST + uri, {
    headers: {
      "Authorization": "Basic YmFsYWFuOmRldmVsb3BiYWxhYW4yMSM=",
      ...getCommonHeaders()
    }
  })).json();
}
export async function callApi(url, {method = 'get', body} = {}) {
  return await (await fetch(url, {
    method,
    ...(method === 'post'? {body: JSON.stringify(body)} : {}),
    headers: {
      'Content-Type': 'application/json',
      ...getCommonHeaders()
    },
    // credentials: 'include',
  })).json();
}

export async function postFeed(uri, body, options) {
  let {signal, ttl, host} = options || {};
  host = host || FEED_HOST;
  const res = await fetch(host + uri, {
    method: 'post',
    headers: {
      "Authorization": "Basic YmFsYWFuOmRldmVsb3BiYWxhYW4yMSM=",
      'Content-Type': 'application/json',
      ...getCommonHeaders()
    },
    credentials: 'include',
    body: JSON.stringify(body),
    signal,
  });
  return await res.json();
}


export async function getMeta(type) {
  const host = getHost();
  const headers = {...getCommonHeaders()};
  ['brand', 'shop', 'category', 'price', 'exchange', 'holiday', 'color', 'designer_sku_pattern'].forEach(e => {
    if (S.meta_checksum[e]) headers[`checksum-${e}`] = S.meta_checksum[e];
  });

  // hash 부터 찾기
  const hashed = await getPubMeta(type);
  if (!hashed) return; // 에러인 경우
  if (type) {
    type = type.split(',').filter(e => !hashed[e]).join(',');
    if (!type) return {ok: 1, ...hashed}
  }

  try {
    const res = await fetch(host + '/meta/meta' + (type ? `?type=${type}` : ''), {headers, credentials: 'include'});
    const j = await handleResult(res, '/meta/meta');
    if (!j) return;

    ['brand', 'shop', 'category', 'price', 'exchange', 'holiday', 'color', 'designer_sku_pattern'].forEach(e => {
      if (j[e] === true) j[e] = S.meta[e];
      else if (j[`checksum_${e}`]) {
        S.meta[e] = j[e];
        S.meta_checksum[e] = j[`checksum_${e}`];
      }
    });
    return {...j, ...hashed};
  } catch (e) {
    alertError(e);
  }
}

export async function postMeta(body) {
  const host = getHost();
  const headers = {...getCommonHeaders()};
  ['brand', 'shop', 'category', 'price', 'exchange', 'holiday', 'color', 'designer_sku_pattern'].forEach(e => {
    if (S.meta_checksum[e]) headers[`checksum-${e}`] = S.meta_checksum[e];
  });

  let type = body.type;
  const hashed = await getPubMeta(type);
  if (!hashed) return; // 에러인 경우
  if (type) {
    type = type.split(',').filter(e => !hashed[e]).join(',');
    if (!type) return {...hashed};
  }

  try {
    const res = await fetch(host + '/meta/meta', {
      method: 'post',
      headers: {'Content-Type': 'application/json', ...getCommonHeaders(), ...headers},
      credentials: 'include',
      body: JSON.stringify(body),
    });
    const j = await handleResult(res, '/meta/meta');
    if (!j) return;

    ['brand', 'shop', 'category', 'price', 'exchange', 'holiday', 'color', 'designer_sku_pattern'].forEach(e => {
      if (j[e] === true) j[e] = S.meta[e];
      else if (j[`checksum_${e}`]) {
        S.meta[e] = j[e];
        S.meta_checksum[e] = j[`checksum_${e}`];
      }
    });
    return {...j, ...hashed};
  } catch (e) {
    alertError(e);
  }
}

export async function getPubMeta(type) {
  if (!type) return {};
  const typeMap = {
    // brand: 'meta_brands', shop: 'meta_shops', category: 'meta_category', price: 'meta_price_meta', exchange: 'meta_exchanges' // shop 은 업데이트 하는곳이 많아서 제외
    brand: 'meta_brands', category: 'meta_category', price: 'meta_price_meta', exchange: 'meta_exchanges'
  }, revTypeMap = {};
  Object.entries(typeMap).forEach(([k, v]) => revTypeMap[v] = k);

  // hash 를 받아오고, 있는것만 객체에 넣어 전달한다.
  const types = type.split(','), ids = types.map(e => typeMap[e]).filter(e => e);
  if (ids.length === 0) return {};
  const j = await getJson('/meta/hash?ids=' + ids.join(','));
  if (j) {
    const hashObj = {};
    await Promise.all(Object.entries(j.url).filter(([, v]) => v).map(([k, v]) => (async (k, v) => {
      hashObj[revTypeMap[k]] = (await (await fetch(v, {headers: {'Accept-Encoding': 'gzip, deflate'}, credentials: 'include'})).json());
    })(k, v)));
    return hashObj;
  }
  return null;
}

export async function getShopPreset() {
  // 기존 prm 체크 후 null 이면 진행 -> 동시 요청시 1개만 실제로 호출한다
  if (S.m.shopPreset.prm === null) {
    const prm = S.m.shopPreset.prm = getJson('/meta/shopPreset');
    const j = await prm;
    S.m.shopPreset.prm = null;
    if (j) {
      if (S.user) j.list.forEach(e => e.hideOnMe = S.user.id.in(e.hide));
      return S.m.shopPreset.list = j.list;
    }
    return [];
  } else {
    await S.m.shopPreset.prm;
    return S.m.shopPreset.list;
  }
}

export async function getBrandPreset() {
  // 기존 prm 체크 후 null 이면 진행 -> 동시 요청시 1개만 실제로 호출한다
  if (S.m.brandPreset.prm === null) {
    const prm = S.m.brandPreset.prm = getJson('/meta/brandPreset');
    const j = await prm;
    S.m.brandPreset.prm = null;
    if (j) {
      if (S.user) j.list.forEach(e => e.hideOnMe = S.user.id.in(e.hide));
      return S.m.brandPreset.list = j.list;
    }
    return [];
  } else {
    await S.m.brandPreset.prm;
    return S.m.brandPreset.list;
  }
}

export async function getCategoryPreset() {
  // 기존 prm 체크 후 null 이면 진행 -> 동시 요청시 1개만 실제로 호출한다
  if (S.m.categoryPreset.prm === null) {
    const prm = S.m.categoryPreset.prm = getJson('/meta/categoryPreset');
    const j = await prm;
    S.m.categoryPreset.prm = null;
    if (j) {
      if (S.user) j.list.forEach(e => e.hideOnMe = S.user.id.in(e.hide));
      return S.m.categoryPreset.list = j.list;
    }
    return [];
  } else {
    await S.m.categoryPreset.prm;
    return S.m.categoryPreset.list;
  }
}

export async function setMeta($this, type) {
  const meta = await getMeta(type); // 'shop,brand,category,holiday'
  if (!meta) return;
  const types = type.split(',');

  if (~types.indexOf('shop')) {
    $this.shop = meta.shop.sort((a, b) => (a.use_yn === 'n' ? 10000 : 0) + a.shop_id - (b.use_yn === 'n' ? 10000 : 0) - b.shop_id);
    $this.shop.forEach(s => {
      s.value = s.boutique;
      s.label = s.text = `${s.use_yn !== 'y' ? '[미사용]' : ''} ${s.shop_id}. ${s.boutique}`;
      $this.shopMap[s.shop_id] = s;
    });
  }

  if (~types.indexOf('brand')) {
    $this.brand = meta.brand.map(e => {
      return $this.brandMap[e.brand_no] = {...e, value: e.brand_no, label: `${e.brand_nm} (${e.brand_nm_kr})`, text: `${e.brand_nm} (${e.brand_nm_kr})`};
    }).sort((a, b) => a.label.localeCompare(b.label));
  }

  if (~types.indexOf('category')) {
    $this.category = meta.category.map(e => {
      const gender = {'009': '여성', '010': '남성'}[e.category.substring(0, 3)] || '';
      return $this.categoryMap[e.category] = {
        ...e, value: e.category, label: `${e.category} (${[gender, e.category_nm].filter(e => e).join(' ')})`,
        text: `${e.category} (${e.category_nm})`
      };
    }).sort((a, b) => (b.value.length - a.value.length) * 10 + a.value.localeCompare(b.value));
  }

  if (~types.indexOf('holiday')) {
    // 공휴일 설정
    let holidays = meta.holiday.map(e => {
      if (e.require) return momentBiz().format('YYYY-') + e.date;
      return e.date;
    });
    // 작년, 내년도 추가한다
    holidays = holidays.concat(meta.holiday.filter(e => e.require).map(e => momentBiz().add(1, 'year').format('YYYY-') + e.date));
    holidays = holidays.concat(meta.holiday.filter(e => e.require).map(e => momentBiz().subtract(1, 'year').format('YYYY-') + e.date));
    momentBiz.updateLocale('kr', {
      holidays: holidays,
      holidayFormat: 'YYYY-MM-DD'
    });
  }
}

/**
 * 최초 로딩시(화면 새로고침시) 전체 메타를 가져와 store 에 넣는다.
 * 이후 socket 으로 변경 trigger 를 받아 다시 로드한다.
 * getAllMeta 로 교체할 때는 필드의 projection 은 문제없는지 체크해야 한다.
 *
 * TODO: 필요시 부분초기화 후 추가 로딩
 */
export async function getAllMeta() {
  if (!S.user.approved) { // 로그인 안된 경우
    S.m.loaded = false;
    return false;
  }
  if (S.m.loaded) return true; // 이미 완료된 경우
  // if (S.m.prm) return S.m.prm;
  if (S.m.prm) { // 최초로딩 (main.js) 에 의해 로딩중인 경우
    const result = await S.m.prm;
    if (result) return true;
  }

  const host = getHost();
  const headers = {...getCommonHeaders()};
  try {
    const res = await fetch(host + '/meta/meta', {headers, credentials: 'include'});
    // 로그인이 되어있지 않아도 redirect 금지, 그렇지 않으면 구글 로그인으로 새 페이지가 로딩될 때 로그인 실패로 redirect 된다.
    const meta = await res.json();
    if (!meta || !meta.ok) {
      S.m.loaded = false;
      S.m.prm = null;
      return false;
    }

    if (meta.shop) {
      meta.shop.forEach(s => {
        s.value = s.shop_id;
        s.label = `${s.use_yn !== 'y' ? '[미사용] ' : ''}${s.shop_id}. ${s.boutique}`;
        S.m.shop.map[s.shop_id] = s;
      }); // use_yn 무관 일단 정보는 필요
      S.m.shop.list = meta.shop.sort((a, b) => (a.use_yn === 'n' ? 100000 : 0) + a.shop_id - (b.use_yn === 'n' ? 100000 : 0) - b.shop_id);
    }

    if (meta.brand) {
      meta.brand.forEach(e => {
        e.value = e.brand_no;
        e.label = `${e.disabled ? `[미사용] ` : ''}${e.brand_no}. ${e.brand_nm} (${e.brand_nm_kr})`;
        S.m.brand.map[e.brand_no] = e;
      });
      S.m.brand.list = meta.brand.sort((a, b) => (a.disabled ? 1000000 : 0) + a.brand_no - (b.disabled ? 1000000 : 0) - b.brand_no);
    }

    if (meta.category) {
      S.m.category.list = meta.category.filter(e => e.category.match(/^(009|010|011|015|0[2-7]0)/)).map(e => {
        return S.m.category.map[e.category] = {
          ...e,
          value: e.category,
          parent: e.category.substring(0, e.category.length - 3),
          label: `${e.category} (${e.category_nm})`
        };
      }).sort((a, b) => (a.value.length - b.value.length) * 10 + a.value.localeCompare(b.value));
      const parentMul = utils.arr2multi(S.m.category.list, 'parent');
      // 하위 카테고리 수, category path 를 넣는다.
      S.m.category.list.forEach(e => {
        const children = parentMul[e.category];
        e.childCnt = children ? children.length : 0;
        e.path = Array(e.category.length / 3).fill(0)
          .map((n, i) => S.m.category.map[e.category.substring(0, i * 3 + 3)].category_nm).join(' > ');
      });
    }

    if (meta.price) {
      meta.price.forEach(e => {
        S.m.price.map[e.price_meta_name] = e.price_meta_value;
      });
      S.m.price.list = meta.price;
    }

    if (meta.exchange) {
      meta.exchange.forEach(e => {
        S.m.exchange.map[e.curr_unit] = e.exchange_ratio;
      });
      S.m.exchange.list = meta.exchange;
    }

    if (meta.holiday) {
      // 공휴일 설정
      let holidays = meta.holiday.map(e => {
        if (e.require) return momentBiz().format('YYYY-') + e.date;
        return e.date;
      });
      // require 에 대해서는 작년, 내년도 추가한다
      holidays = holidays.concat(meta.holiday.filter(e => e.require).map(e => momentBiz().add(1, 'year').format('YYYY-') + e.date));
      holidays = holidays.concat(meta.holiday.filter(e => e.require).map(e => momentBiz().subtract(1, 'year').format('YYYY-') + e.date));
      S.m.holiday.list = holidays;
      S.m.holiday.map = utils.arr2map(holidays);
      momentBiz.updateLocale('kr', {
        holidays: holidays,
        holidayFormat: 'YYYY-MM-DD'
      });
    }

    if (meta.color) {
      meta.color.forEach(s => {
        s.label = `${s.color} (${s.hexcolor})`;
        if (s.color === 'multi') {
          s.label = `${s.color} (Multi Color)`;
          s.hexcolor = `linear-gradient(135deg, red,orange,yellow,green,blue,indigo,violet)`;
        }
      });
      S.m.color.list = meta.color.sort((a, b) => a.color.localeCompare(b.color));
    }

    if (meta.designer_sku_pattern) {
      S.m.designer_sku_pattern.list = meta.designer_sku_pattern.map(e => {
        return S.m.designer_sku_pattern.map[e.brand_no] = e;
      });
    }

    if (meta.coupon) {
      S.m.coupon.list = meta.coupon.map(e => {
        return S.m.coupon.map[e.couponcd] = e;
      });
    }

    // console.log('meta done', S.user.approved, S.m.loaded, S.m.prm);
    S.m.loaded = true;
    S.m.prm = null;
    return true;
  } catch (e) {
    S.m.loaded = false;
    S.m.prm = null;
    alertError(e);
  }
  return false;
}


/** get, post Part **/

export async function getJson(uri, options) {
  const {signal, ttl, headers} = options || {};
  const host = getHost();
  try {
    const res = await fetch(host + uri, {headers: {...getCommonHeaders(), ...headers}, credentials: 'include', signal});
    return handleResult(res, uri);
  } catch (e) {
    alertError(e);
  }
}

export async function postJson(uri, body, options) {
  const {signal, ttl, headers} = options || {};
  const host = getHost();
  try {
    const res = await fetch(host + uri, {
      method: 'post',
      headers: {'Content-Type': 'application/json', ...getCommonHeaders(), ...headers},
      credentials: 'include',
      body: JSON.stringify(body),
      signal,
    });
    return handleResult(res, uri);
  } catch (e) {
    alertError(e);
  }
}

export async function postForm(uri, body, options) {
  const {signal, ttl} = options || {};
  const host = getHost();
  let formData = new FormData();
  if (body instanceof FormData) {
    formData = body;
  } else {
    Object.entries(body).forEach(([k, v]) => {
      if (utils.typeOf(v) === 'filelist' || utils.typeOf(v) === 'array') {
        for (const e of v) {
          formData.append(k, e);
        }
      } else if (utils.typeOf(v) === 'file') {
        formData.append(k, v)
      } else if (typeof v === 'object') {
        formData.append(k, JSON.stringify(v))
      } else {
        formData.append(k, v)
      }
    });
  }
  try {
    const res = await fetch(host + uri, {
      method: 'post',
      headers: {...getCommonHeaders()},
      credentials: 'include',
      body: formData,
      signal,
    });
    return handleResult(res, uri);
  } catch (e) {
    alertError(e);
  }
}

function alertError(e) {
  // 서버가 안켜져있다면 TypeError: Failed to fetch
  if (e.message === 'Failed to fetch') {
    Vue.prototype.$alertTop(`서버에 연결할 수 없습니다. 서버를 확인해주세요`, {variants: 'danger'});
  }
}

async function handleResult(res, uri) {
  if (res.status === 401) {
    S.user.approved = false;
    localStorage.removeItem('user');
    // 만약 로그인으로 이동중이라면 무시한다.
    if (router.history.current.path !== '/pages/login') {
      router.push('/pages/login?go=' + encodeURIComponent(router.history.current.fullPath));
    }
    return false;
  } else if (res.status === 403) {
    // 권한이 없는 경우 - 아래의 alert 으로 메시지 표시
  } else if (res.status === 404) {
    utils.alert(`${uri} 주소를 찾을 수 없습니다`);
    return false;
  } else if (res.status === 500) {
    // router.push('/pages/500');
    const isJson = res.headers.get('content-type').includes('application/json');
    if (isJson) {
      utils.alert(`${uri} 에서 500 에러가 발생했습니다 : ` + (await res.json()).msg);
    } else {
      utils.alert(`${uri} 에서 500 에러가 발생했습니다 : ` + await res.text());
    }
    return false;
  }
  try {
    const j = await res.json();
    if (!j.ok) {
      utils.alert(j.msg);
      return false;
    }
    return j;
  } catch (err) {
    console.error(err);

    return utils.alert([
      `${uri} 주소의 응답형식이 잘못되었습니다:`,
      `${err}`,
    ].join('\n'));
  }
}


/**
 * getMore, abortController 등을 기본 탑재한 형태의 postJson.
 * this에 lastBody, items, busy, hasMore, total, ac 등이 object로 존재해야 한다.
 * more 가 필요없는 경우 hasMore 는 필수가 아니다.
 * more 를 이용하는 경우 body 에는 skip, limit 이 존재해야 한다.
 * options = {key, ttl, more, fnAssign, fnAfter}
 *
 * ex) this.$api.postTable(this, '/settle/admin', body, {fnAfter:j=>{}});
 * ex) this.$api.postTable(this, '/settle/admin/detail', body, {key:'detail', fnAssign:this.assignDetailTableData});
 *
 * @param {object} $this                 vue 의 this 객체
 * @param {string} uri                   table 데이터를 가져오기 위해 호출할 uri
 * @param {object} body                  post 의 body(검색조건 등)
 * @param {string} [key=list]            items, lastBody, busy, ac 등에서 사용할 key
 * @param {number} [ttl]                 AbortController 에서 사용할 signal 의 ttl
 * @param {boolean} [more]               이 요청이 more 인가(다음 페이지 추가요청이라 현재의 form 을 사용하지 않고 마지막 검색조건을 사용하는가)
 * @param {function} [fnAssign]          가져온 결과 row 마다 실행할 함수(추가정보 생성)
 * @param {function} [fnAfter]           전체 응답결과(j) 를 대상으로 실행할 함수이며, 이 함수가 있다면 fnAssign, removeDupId 제외는 실행되지 않는다.
 * @param {string} [removeDupId]         items 가 겹치면 안된다면, removeDupId 를 체크하여 신규 데이터의 Id 가 기존의 Id 와 겹친다면 제외한다.
 * @param {string} [uniqueId]            uniqueId 가 있다면 more 로 페이징을 해 올때, sortKey 와 uniqueId 의 마지막 값을 보내서 이후의 값을 가져오도록 한다.
 * @return {object}                      post 의 결과물, 보통 $this 를 통해 직접 데이터를 업데이트하고 j 는 조건부로 사용한다.
 */
export async function postTable($this, uri, body, {
  key = 'list', ttl, more, fnAssign, fnAfter, removeDupId = '', uniqueId= ''
} = {}) {
  const {lastBody, items, busy, hasMore, total, lastSort, ac} = $this;
  const moreAvail = lastBody && hasMore && (body.limit || body.form && body.form.limit) && more === true;

  if (moreAvail) {
    if (busy[key + 'more']) return; // 이전요청을 기다린다
    body = lastBody[key];
    if (body.limit) {
      body.skip += body.limit;
    } else if (body.form) {
      body.form.skip += body.form.limit;
    }
    busy[key + 'more'] = true;
  } else {
    if (lastBody) lastBody[key] = utils.clone(body);
    busy[key] = true;
  }

  let j;
  try {
    ac[key] && ac[key].abort();
    ac[key] = new AbortController();
    if (more !== true && $this.$refs[`c-table:${key}`]) { // 페이지를 1로 초기화
      $this.$refs[`c-table:${key}`].resetPage();
    }

    let lastValueObj = null;
    if (moreAvail && uniqueId && lastSort) {
      lastValueObj = {_lastSort: lastSort[key]};
    }
    j = await postJson(uri, {...body, ...lastValueObj}, {signal: ac[key].signal, ttl});
    if (hasMore) hasMore[key] = j.hasMore;
    if (total) {
      if (j.total) {
        total[key] = j.total; // {value: 0, relation: 'eq'}
        if (lastValueObj && lastSort[key]) { // lastSort 가 있었다면, total 에 영향을 미친다. skip 만큼 추가해야 한다. 백엔드 es에서 skip 이 사용되지 않았을때 한정이다.
          j.total.value += body.limit ? body.skip : body.form ? body.form.skip : 0;
        }
      } else {
        total[key] = null;
      }
    }
    if (lastSort) {
      if (j.lastSort) {
        lastSort[key] = j.lastSort; // [sortKeyValue, uniqueId] or [uniqueId]
      } else {
        lastSort[key] = null;
      }
    }
  } catch (e) {
    if (!more) items[key].splice(0, items[key].length);
    return;
  } finally {
    ac[key] = null;
    if (moreAvail) {
      busy[key + 'more'] = false;
    } else {
      busy[key] = false;
    }
  }

  if (!j) return;
  if (fnAfter) {
    try {
      fnAfter(j);
    } catch (e) { // fnAfter 중 에러가 발생할 수 있다.
      utils.alert(`데이터 후처리(fnAfter) 중 에러가 발생했습니다`);
    }
  } else {
    try {
      fnAssign && j.list.forEach(fnAssign);
    } catch (e) { // fnAssign 중 에러가 발생할 수 있다.
      console.error(e);
      utils.alert(`데이터 후처리(fnAssign) 중 에러가 발생했습니다`);
    }
    if (moreAvail) {
      let list = j.list;
      if (removeDupId) {
        const idMap = utils.arr2map(items[key], removeDupId, true);
        list = j.list.filter(e => !idMap[e[removeDupId]]);
      }
      items[key].splice(items[key].length, 0, ...list);
    } else {
      items[key] = j.list;
    }
  }

  return j;
}

export async function sendBeacon(uri, body) {
  const host = getHost();
  const blob = new Blob([JSON.stringify(body)], {
    type: 'application/json'
  })

  navigator.sendBeacon(host + uri, blob);
}


export async function get(uri) {
  const host = getHost();
  return await fetch(host + uri, {headers: {...getCommonHeaders()}, credentials: 'include'});
}

export function open(uri) {
  const host = getHost();
  return utils.open(host + uri);
}

export async function downloadFile(targetUri, queryParams, donwloadFileName) {
  const hostUri = getHost();
  const params = new URLSearchParams();

  Object.entries(queryParams).forEach(([key, value]) => {
    params.append(key, value);
  });

  const response = await fetch(`${hostUri}${targetUri}?${params.toString()}`, {
    method: 'GET',
    headers: {
      'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    }
  });

  const arrayBuffer = await response.arrayBuffer();
  const blob = new Blob([arrayBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  });

  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = donwloadFileName
  document.body.appendChild(a)
  a.click()
  a.remove()
  window.URL.revokeObjectURL(url)
}
