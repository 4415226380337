import {typeOf} from './utils';
import {export_json_to_excel} from '@/shared/Export2Excel';
import XLSX from 'xlsx'

export async function down(list, header, fields, filename = 'export', bookType = 'xlsx', options = {}) {
  // options : types:{link:'link'}, sheetName:'Products'
  let data = list.map(e => remapObj(e));

  // fields 가 없다면 모든 row 를 중첩시킨 뒤 추출한 전체 키를 field로 한다.
  if (!fields || !fields.length) {
    let dupObj = {};
    data.forEach(e => Object.assign(dupObj, e));
    fields = Object.keys(dupObj);
  }
  // header 가 없다면 fields 로 대체
  if (!header) header = fields;

  // fields 에 해당하는 데이터만 남도록 재구성한다.
  data = data.map(obj => {
    return fields.map(f => obj[f]);
  });

  export_json_to_excel({
    header,
    fields,
    data,
    filename,
    autoWidth: true,
    bookType,
    ...options
  })
}

/**
 * csv export part
 */
export function convertToCSV(array) {
  /**
   * 모든 키를 확인하기 위해 모든 데이터를 중첩시킨 뒤 키를 확보한다.
   * 해당 키를 바탕으로 csv 형태를 만든다.
   */
  let str = '';
  let arr = array.map(e => remapObj(e));
  let dupObj = {};
  arr.forEach(e => Object.assign(dupObj, e));
  let keys = Object.keys(dupObj);
  str = keys.map(e => `"${e.replace(/"/g, '""')}"`).join(',') + '\r\n';
  arr.forEach(row => {
    str += keys.map(k => {
      return row[k] != null ? `"${(row[k] + '').replace(/"/g, '""')}"` : '';
    }).join(',') + '\r\n';
  });
  return str;
}

export function remapObj(obj, prefix = '') {
  /**
   * a:{b:1}, c:[2,3] 등의 중첩된 형태를 엑셀형태로 바꾸기 위해 a.b, c.0 으로 바꾸어 매핑한다
   */
  let reMap = {};
  if (typeOf(obj) === 'object') {
    Object.entries(obj).forEach(([k, v]) => {
      Object.assign(reMap, remapObj(v, (prefix ? prefix + '.' : '') + k));
    });
  } else if (typeOf(obj) === 'array') {
    obj.forEach((e, i) => {
      Object.assign(reMap, remapObj(e, (prefix ? prefix + '.' : '') + i));
    });
  } else {
    reMap[prefix] = obj;
  }
  return reMap;
}

export function exportCSVFile(headers, items, fileTitle) {
  // Convert Object to JSON
  let csv = convertToCSV(items);
  if (headers) {
    csv.unshift(headers);
  }
  let exportedFilenmae = fileTitle + '.csv' || 'export.csv';
  let blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
  if (navigator.msSaveBlob) { // IE 10+
    navigator.msSaveBlob(blob, exportedFilenmae);
  } else {
    let link = document.createElement("a");
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      let url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportedFilenmae);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}


/**
 * read xlsx file
 */
export function getHeaderRow(sheet) {
  let headers = [], range = XLSX.utils.decode_range(sheet['!ref']);
  let C, R = range.s.r; /* start in the first row */
  for (C = range.s.c; C <= range.e.c; ++C) { /* walk every column in the range */
    let cell = sheet[XLSX.utils.encode_cell({c: C, r: R})]; /* find the cell in the first row */
    // let hdr = "UNKNOWN " + C; // <-- replace with your desired default
    let hdr = '';
    if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);
    headers.push(hdr);
  }
  return headers.filter(e => e); // 빈 헤더 제외
}

export function fixData(data) {
  let o = "", l = 0, w = 10240;
  for (; l < data.byteLength / w; ++l) o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w, l * w + w)));
  o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)));
  return o;
}

export function workbookToJson(workbook) {
  let result = {};
  workbook.SheetNames.forEach(function (sheetName) {
    let roa = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
    if (roa.length > 0) {
      result[sheetName] = roa;
    }
  });
  return result;
}

export async function readXlsx(file, {multi = false} = {}) {
  let reader = new FileReader(), name = file.name;
  return await new Promise((resolve, reject) => {
    reader.onload = (e) => {
      try {
        let data = e.target.result, fixedData = fixData(data), workbook = XLSX.read(btoa(fixedData), {type: 'base64'});
        if (multi) {
          const sheets = workbook.SheetNames.map(e => {
            const sheet = workbook.Sheets[e];
            const rows = XLSX.utils.sheet_to_json(sheet);
            const headers = getHeaderRow(sheet);
            return {name: e, headers, rows};
          });
          resolve({name, sheets});
        } else {
          const sheet = workbook.Sheets[workbook.SheetNames[0]];
          resolve({headers: getHeaderRow(sheet), rows: XLSX.utils.sheet_to_json(sheet, { defval: "" })});
        }
      } catch (e) {
        reject(e);
      }
    };
    reader.readAsArrayBuffer(file);
  });
}
